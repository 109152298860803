import { environment } from '@env/index';
import * as $ from 'jquery';
import { Region, allCountries } from 'country-region-data';
import { countriesNotMapping, monthCanMakeEarlySubscription } from '@app/constants/constants';

export const isValueExists = (value: any): boolean => value !== undefined && value !== null && value !== '';

// tslint:disable-next-line:ban-types
export const callSafely = (fn: Function, value: any) => {
    if (!isValueExists(value)) {
        return value;
    }

    return fn(value);
};

export const getKeyLightHouse = (inputFile) => {
  const fileReader = new FileReader();
  const headerLightHouseID = '!RAYMARINE LIGHTHOUSE MEDIA ID FILE';

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      let value = fileReader.result.toString().replace(headerLightHouseID, '').trim();
      value = value === '' ? 'empty' : value;
      resolve(value);
    };

    fileReader.readAsText(inputFile);
  });
};

export const isMobileView = (): boolean => {
  return window.innerWidth <= environment.widthForMobileView;
};

export const getRandomNumber = (): number => {
  return Math.random();
};

export const isNullOrEmpty = (data: string): boolean => {
  return (data === '' || data === null) ? true : false;
};

export const getMinWidth = (minWidth: number, value: number): number => {
  return minWidth < value ? value : minWidth;
};

export const setEqualWidthForButtons = () => {
  $(document).ready(() => {
    let minWidth = $('.align-div').first().find('.align-button').outerWidth();
    $('.align-div').each(function() {
      minWidth = getMinWidth(minWidth, $(this).find('.align-button').outerWidth());
    });

    $('.align-div').each(function() {
      $(this).find('.align-button').outerWidth(minWidth);
    });
  });
};

export const getCountryNameMappedWithCountryNameInSSO = (iso2: string): string => {
  let country = countriesNotMapping.find(country => country.code === iso2);
  if (country) {
    return country.countryName;
  }
     
  return allCountries.find(countries => countries[1] === iso2)?.[0];
}

export const getListStatesByCountryCodeIso2 = (iso2: string): Region[] => {
  return allCountries.find(countries => countries[1] === iso2)?.[2];
}

export const canEarlyRenew = (expiresDateTime: string): boolean => {
  let expiresDateTimeConverted = new Date(expiresDateTime);
  let currentDateTime = new Date(Date.now());
  if (expiresDateTimeConverted.getTime() - currentDateTime.getTime() < 0) {
    return false;
  }
  else {
    let monthDiff = expiresDateTimeConverted.getMonth() - currentDateTime.getMonth();
    let yearDiff = expiresDateTimeConverted.getFullYear() - currentDateTime.getFullYear();
    if ((monthDiff + yearDiff * 12) <= monthCanMakeEarlySubscription) {
      return true;
    }
    else {
      return false;
    }
  }
}