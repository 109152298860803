import { defaultCurrencyAndUnit, ProvisioningStatus, TaxInclude } from '@app/constants';
import { State } from '@app/core/features';
import { createSelector, select } from '@ngrx/store';
import { AuthState } from '../../auth';
import { ProductModel } from '../../charts-store';
import { ProductZuoraCartItem } from '../payment.models';
import {
	CheckoutState, Currency, PreviewOrderResponse, ProductRatePlan
} from './checkout.models';

const getLocalState = (state: State): CheckoutState => state.payment.checkout;

const getAuthState = (state: State): AuthState => state.auth;

const getRatePlanList = createSelector(
	getLocalState,
	(state: CheckoutState): any => state.ratePlanList
);

const getRatePlanByPremiumSku = (cartItem: ProductZuoraCartItem[], taxIncluded: boolean, translatedProducts: ProductModel[]) => createSelector(
	getLocalState,
	getAuthState,
	(state: CheckoutState, authState: AuthState): ProductRatePlan[] => {

		if (state.ratePlanList && state.ratePlanList.length > 0) {
			const result = [];

			cartItem.forEach(cart => {
				let selectedPlan = state.ratePlanList.find(plan => plan.sku === cart.skuPremium && (taxIncluded ? plan.taxMode.toLocaleLowerCase().includes(TaxInclude.toLocaleLowerCase()) : !plan.taxMode.toLocaleLowerCase().includes(TaxInclude.toLocaleLowerCase())));
				let pricing = selectedPlan.pricing.find(item => {
					if (item.currency === authState.data.currency || (!authState.data.currency && item.currency === defaultCurrencyAndUnit.currency)) {
						return item.currency;
					}
				});
				pricing = Object.assign({ unit: authState.data.unit ? authState.data.unit : defaultCurrencyAndUnit.unit }, pricing, { price: pricing.price.toFixed(2) });
				// Get name and description for product from API - translate product
				var translatedProduct = translatedProducts.find(x => getMainSku(selectedPlan.sku) === getMainSku(x.sku));

				selectedPlan = Object.assign(
					{ filteredPricing: pricing },
					{ chartEntitlementId: cart.chartEntitlementId },
					{ autoRenew: cart.autoRenew },
					selectedPlan,
					{ name: translatedProduct.premiumName },
					{ description: translatedProduct.premiumName },
					{isEarlyRenewal: cart.isEarlyRenewal},
					{subscriptionNumber: cart.subscriptionNumber},
					{termStartDate: cart.termStartDate}
				);

				result.push(selectedPlan);
			})

			return result;
		}
	}
);

const getProvisioningStatus = (subscriptionNumbers: string[]) => createSelector(
	getLocalState,
	(state: CheckoutState): ProvisioningStatus => {
		if (!state.provisioningStatus || state.provisioningStatus.length === 0 || state.provisioningStatus.length < subscriptionNumbers.length) {
			return ProvisioningStatus.Processing;
		} else {
			return state.provisioningStatus.every(item => item.status === true) ? ProvisioningStatus.Successful : ProvisioningStatus.Failed;
		}
	}
);

const getListCurrenciesAndTaxes = () => createSelector(
	getLocalState,
	(state: CheckoutState): Currency[] => state.currenciesAndTaxes
);

const getMainSku = (sku: string) => {
	var splittedSku = sku.split('-');
	return splittedSku[1];
}

const getPreviewOrder = () => createSelector(
	getLocalState,
	(state: CheckoutState): PreviewOrderResponse => state.previewOrderResult
);

const mappingRatePlanAndPreviewOrder = (productRatePlan: ProductRatePlan[], unit: string) => createSelector(
	getLocalState,
	(state: CheckoutState): PreviewOrderResponse => {
		let amountResult;
		let amountWithoutTaxResult;
		let taxAmountResult;
		unit = unit != null ? unit : defaultCurrencyAndUnit.unit;

		if (productRatePlan && productRatePlan.length > 0 && state.previewOrderResult && state.previewOrderResult.success === true) {
			var invoiceItems = []
			productRatePlan.filter(ratePlan => state.previewOrderResult.previewResult.invoices.invoiceItems.some(
				(invoice) => ratePlan.sku === invoice.chargeDescription
					&& invoiceItems.push({
						...invoice,
						productName: ratePlan.name,
						chartEntitlementId: ratePlan.chartEntitlementId,
						autoRenew: ratePlan.autoRenew,
						amountWithoutTax: (invoice.amountWithoutTax).toFixed(2),
						taxAmount: (invoice.taxAmount).toFixed(2),
					})
			));

			// Set unit for order
			unit = productRatePlan[0].filteredPricing.unit ? productRatePlan[0].filteredPricing.unit : unit;

			// Calculate amount and tax
			amountWithoutTaxResult = (invoiceItems.reduce((sum, item) => sum + Number(item.amountWithoutTax), 0)).toFixed(2);
			taxAmountResult = (invoiceItems.reduce((sum, item) => sum + Number(item.taxAmount), 0)).toFixed(2);
			amountResult = (Number(amountWithoutTaxResult) + Number(taxAmountResult)).toFixed(2);

			var result: PreviewOrderResponse = {
				success: state.previewOrderResult.success,
				previewResult: {
					invoices: {
						amount: amountResult,
						amountWithoutTax: amountWithoutTaxResult,
						taxAmount: taxAmountResult,
						targetDate: state.previewOrderResult.previewResult.invoices.targetDate,
						invoiceItems,
						unit
					}
				}
			}
			return result;
		}
		if (state.previewOrderResult && state.previewOrderResult.success === false) {
			var result: PreviewOrderResponse = {
				success: state.previewOrderResult.success,
				previewResult: null
			}
			return result;
		}

	}
);

export const selectors = {
	getRatePlanList: select(getRatePlanList),
	getRatePlanByPremiumSku: (zuoraCart: ProductZuoraCartItem[], taxIncluded: boolean, translatedProducts: ProductModel[]) => select(getRatePlanByPremiumSku(zuoraCart, taxIncluded, translatedProducts)),
	getProvisioningStatus: (subscriptionNumbers: string[]) => select(getProvisioningStatus(subscriptionNumbers)),
	getListCurrenciesAndTaxes: () => select(getListCurrenciesAndTaxes()),
	getPreviewOrder: () => select(getPreviewOrder()),
	mappingRatePlanAndPreviewOrder: (productRatePlan: ProductRatePlan[], unit: string) => select(mappingRatePlanAndPreviewOrder(productRatePlan, unit))
};
