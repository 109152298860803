import { httpMethods } from './http';

export const endpoints = {
  getUserInfo: '/Users/',
  toggleFirstTimeDraw: '/Users/Toggle-Is-First-Time-Draw',
  toggleFirstTimeReachBoxLimit: '/Users/Toggle-Is-First-Time-Reach-Box-Limit',
  getMyChartsList: '/Entitlements/Chart-Entitlements/',
  getMyChartsListDealer: '/Entitlements/Chart-Entitlements-Dealer/',
  getProduct: '/Products/',
  getProductBySku: '/Products/sku/',
  chartItemData: '/Area-Of-Interest/Chart-Entitlement/',
  postItemData: '/Area-Of-Interest/Chart-Entitlements/Save-Many',
  updateItemData: '/Area-Of-Interest/',
  deleteManyChartItems: '/Area-Of-Interest/Delete-Many/',
  getAoiSizeEstimate: '/Area-Of-Interest/Size-Estimate',
  updateChartEntitlementsData: '/Area-Of-Interest/Chart-Entitlement/Update-Data',
  toggleIncludeDownload: '/Entitlements/Chart-Entitlements/{id}/Toggle-Include-In-Download',
  markAsNotNew: '/Entitlements/Chart-Entitlements/{id}/Mark-As-Not-New',
  getCode: '/Voucher/Code/',
  getChartEntitlementById: '/Entitlements/Chart-Entitlements/',
  getPackageStatus: '/User-Devices/Status/',
  postAssignChartEntitlement: '/Entitlements/Chart-Entitlements/Assign',
  cart: '/cart',
  cartItems: '/cart/items',
  getUserDevice: '/User-Devices',
  removePremiumAoisAndForceRepackage: '/Remove-Premium-Aois-And-Force-Repackage',
  forceRepackage: '/Force-Repackage',
  markReadyToPackage: '/Mark-Ready-To-Package',
  retryFailedPremiumAoisAndForceRepackage: '/Retry-Failed-Premium-Aois-And-Force-Repackage',
  markDownloadInitiated: '/Mark-Download-Initiated',
  markSendEmailNotificationOnDownloadReady: '/Mark-Send-Email-Notification-On-Download-Ready',
  redeemTrialVoucher: '/Voucher/Redeem-Trial-Voucher',
  redeemVoucher: '/Voucher/Redeem-Voucher',
  getVoucherIncludeChartEntitlement: '/Voucher/Code/GetVoucherIncludeChartEntitlement/',
  getVoucherIncludeChartEntitlementById: '/Voucher/Voucher-Include-ChartEntitlement/',
  expireTrialNow: '/Entitlements/Chart-Entitlements/{id}/Expire-Trial',
  endTrial: '/Entitlements/Chart-Entitlements/{id}/End-Trial',
  chartFeedback: '/ChartFeedback',
  addChartFeedback: '/Add-Chart-Feedback',
  checkSaveDetail: '/Check-Save-Detail',
  health: '/health',
  dealerTransfer: '/Dealer/Transfer',
  dealerConfirm: '/Dealer/Confirm',
  dealerCancel: '/Dealer/Cancel',
  dealerUpdateChartCustomName: '/Dealer/Update-Chart-Custom-Name',
  updateChartNotes: '/Entitlements/Update-Notes',
  saveLanguage: '/Users/Save-Language',
  getAllProductTranslations: 'Get-All-Product-Translations/',
  getMigrationOptions: '/Entitlements/Migration-Options',
  migrateMergeChart: '/Entitlements/Migrate-Merge-Chart',
  clearUserDevice: '/User-Devices/Clear-User-Device',
  getMFDType: '/Users/Update-MFD-Type/',
  zuora: '/Zuora',
  getRsaSignature: '/GetRsaSignature',
  getRatePlanList: '/rateplan',
  subscription: '/Subscription',
  subscriptions: '/Subscriptions',
  update: '/Update',
  create: '/Create',
  order: '/Order',
  account: '/Account',
  user: '/User',
  list:'/List',
  summary: '/Summary',
  accountSummary: '/RetrieveAnAccountSummary/',
  getInvoiceFile: '/GetInvoiceFile/',
  getStatusProvisioning: '/Get-Status-Provisioning',
  getListCurrenciesAndTaxes: '/Get-List-Currencies-And-Taxes',
  getAvailableProductsByVoucher: '/Voucher/GetAvailableProductsByVoucher/',
  preview: '/Preview',
  profile: '/Profile',
  storedCredentialConsent: '/Stored-Credential-Consent',
  getContent: '/Get-Content',
  save: '/Save',
  paymentMethod: '/Payment-Method/',
  getListPaymentMethod: 'Get-List-Payment-Methods/',
  invoices: '/Invoices',
  accounts : '/Accounts/',
  provisioning: '/Provisioning',
  completeProvisioningCallBack: '/Complete-Provisioning-CallBack',
  updateSmallChartDownloadedAcknowledged: '/Entitlements/Chart-Entitlements/Small-Chart-Downloaded-Acknowledged',
  maintenanceMode: '/Maintenance-Mode',
  flag: '/Flag',
  renew: '/Renew'
};

export const gisApiEndpoints = {
  health: '/health/version',
};

export const excludeAuthRequests = [
  {
    url: endpoints.getProduct,
    method: httpMethods.GET
  },
  {
    url: endpoints.health,
    method: httpMethods.GET
  },
  {
    url: endpoints.getProductBySku,
    method: httpMethods.GET
  },
  {
    url: gisApiEndpoints.health,
    method: httpMethods.GET
  }
];
