
export const VIDEO_SOURCE = {
    FIRST_DRAW: [
        'assets/videos/draw_01_and_02.mp4',
    ],
    FIRST_REACH_BOX_LIMIT: [
        'assets/videos/draw_01_and_02.mp4',
        'assets/videos/reach_box_01.mp4',
        'assets/videos/reach_box_02.mp4',
    ],
    HOW_TO:[
        '8JE-nxFuKQo',
        'EBhoRWIPVSg',
        'nfp2bHgzMsw',
        'f4w5BGz43wI'
    ]
};