export interface CheckoutState {
  ratePlanList: ProductRatePlan[];
  provisioningStatus: GetStatusProvisioningResponse[];
  currenciesAndTaxes: Currency[];
  previewOrderResult : PreviewOrderResponse
  isFetching?: boolean;
  error?: any;
  isEmpty?: boolean;
}

export interface GetRatePlanListQuery {
  request: string;
  pageNum?: number;
}

export interface GetRatePlanListResponse {
  productRatePlans: ProductRatePlan[];
  nextPage: number;
  success: boolean;
}

export interface CheckoutCartModel {
  id: string;
  name: string;
  sku: string;
  description: string;
  pricing: RatePlanPricing
}

export interface ProductRatePlanCharges {
  id: string;
  name: string;
  description: string;
  pricing: RatePlanPricing[];
};

export interface RatePlanPricing {
  price: number;
  currency: string;
  unit: string;
}

export interface ProductRatePlan {
  id: string;
  sku: string;
  name: string;
  description: string;
  status: string;
  productRatePlanCharges: ProductRatePlanCharges[];
  pricing: RatePlanPricing[];
  filteredPricing?: RatePlanPricing;
  chartEntitlementId: string;
  taxMode: string;
  autoRenew: boolean;

  initialTerm?: string;
  renewalTerm?: string;
  planTier?: string;
  planType?: string;
  productFamily?: string;
  provisionPlanId?: string;
  segment?: string;
  system?: string;
}

export const productRatePlanConvertSchema = {
  id: 'id',
  sku: 'flir_product_id__c',
  name: 'name',
  description: 'description',
  status: 'status',
  pricing: ({ productRatePlanCharges }) => {
    return productRatePlanCharges[0].pricing;
  },
  taxMode: ({ productRatePlanCharges }) => {
    return productRatePlanCharges[0].taxMode;
  },
  initialTerm: 'InitialTerm__c',
  renewalTerm: 'RenewalTerm__c',
  planTier: 'PlanTier__c',
  planType: 'planType__c',
  productFamily: 'productFamily__c',
  provisionPlanId: 'provisionPlanId__c',
  segment: 'segment__c',
  system: 'system__c'
};

export interface CreateOrderRequest {
  transactionId: string;
  orderDate: string;
  currency: string;
  paymentMethodId: string;
  source: string;
  lineItem: OrderLineItem[];
  customerInfo: {
    zuoraAccountId: string;
  };
  termStartDate?: string;
}

export interface OrderLineItem {
  quantity: number;
  productInfo: ProductInfo;
  isEarlyRenewal? : boolean;
  subscriptionNumber?: string;
  termStartDate?: string;
}

export interface ProductInfo {
  productId: string;
  ratePlanId: string;
  period: number;
  periodType: string;
  sku: string;
  chartEntitlementId: string;
  autoRenew: boolean;
}

export interface CreateOrderResponse {
  success: boolean;
  orderNumber: string;
  accountNumber: string;
  status: string;
  subscriptionNumbers: string[];
  processId: string;
  reasons: []
}

export interface CreateAccountRequest {
  transactionId: string;
  source: string;
  currency: string;
  language: string;
  productInfo: {
    flirProductId: string;
    segment: string;
  };
  customerInfo: CustomerInfo;
}

export interface CustomerInfo {
  flirId?: string;
  firstName: string;
  lastName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  email: string;
  zuoraAccountId?: string;
  defaultPaymentMethodId?: string;
}

export interface CreateAccountResponse {
  success: boolean;
  zuoraAccountId: string;
  accountId: string;
  paymentGateway: string;
  existingZuoraAccountId: string;
  existingAccountId: string;
  reasons: any;
}

export interface GetStatusProvisioningRequest {
  subscriptionNumbers: string[];
}

export interface GetStatusProvisioningResponse {
  subscriptionNumber: string[],
  status: boolean;
}

export interface Currency {
  id: string;
  iso2: string;
  longName: string;
  org: string;
  blockSales: boolean;
  currency: string;
  chargeTax: boolean;
}

/* #region Model for preview order request  */
export interface PreviewOrderRequest {
  transactionId: string;
  source: string;
  currency: string;
  lineItem: PreviewOrderLineItem[];
  customerInfo: PreviewOrderCustomerInfo;
}

export interface PreviewOrderLineItem {
  quantity: number;
  productInfo: PreviewOrderProductInfo;
}

export interface PreviewOrderProductInfo {
  productId: string;
  ratePlanId: string;
}

export interface PreviewOrderCustomerInfo {
  flirId: string;
  zuoraAccountId: string;
  state: string;
  country: string;
  postalCode: string;
}
/* #endregion Model for preview order request */

/* #region Model for preview order response */
export interface PreviewOrderResponse {
  success: boolean;
  previewResult: PreviewResult;
}

export interface PreviewResult {
  invoices: Invoices;
}

export interface Invoices {
  amount: number;
  amountWithoutTax: number;
  taxAmount: number;
  targetDate: Date;
  invoiceItems: InvoiceItem[];
  unit: string
}

export interface InvoiceItem {
  serviceStartDate: Date;
  serviceEndDate: Date;
  amountWithoutTax: number;
  taxAmount: number;
  chargeDescription: string;
  chargeName: string;
  chargeNumber: string;
  processingType: string;
  productName: string;
  productRatePlanChargeId: string;
  subscriptionNumber: string;
  additionalInfo: AdditionalInfo;
  chartEntitlementId: string;
  autoRenew: boolean;
}

export interface AdditionalInfo {
  quantity: number;
  unitOfMeasure: string;
}
/* #endregion Model for preview order response */
export interface UpdateAccountRequest {
  transactionId: string;
  source: string;
  language: string;
  customerInfo: CustomerInfo;
}

/* #region Model for complete provisioning callback */
export interface CompleteProvisioningCallBackRequest {
  zuoraAccountId: string;
  startDate: string;
  endDate: string;
  listProducts: ProductInfoForCompleteProvisioningCallBack[]
}

export interface ProductInfoForCompleteProvisioningCallBack {
  sku: string;
  chartEntitlementId: string;
}
/* #endregion Model for complete provisioning callback */

export interface RenewOrderForZuoraRequest{
  source: string;
  subscriptionNumber: string;
  zuoraAccountId: string;
  paymentMethodId: string;
}

export interface RenewOrderForZuoraResponse {
  success: boolean;
  orderNumber: string;
  accountNumber: string;
  status: string;
  subscriptionNumbers: string[];
  processId: string;
  reasons: [];
}