import { PolygonData } from '@app/core/features/charts-manager/my-charts/my-chart-item';
import { areaOfInterestType } from '@app/constants/';

export interface MyChartListFetching {
  isDataFetching: boolean;
}

export interface GetProductRequest {
  productId: string;
}

export interface AreaOfInterest {
  id: string;
  polygon: PolygonData;
  areaOfInterestType: areaOfInterestType;
  packageStatus: number;
  latestPackageSuccess: string;
  latestPackageStart: string;
  lastDownloadedProductVersion: string;
  createdDateTime: string;
  lastModifiedDateTime: string;
  sizeKb: number;
  errorCode: string;
}

export interface MyChartsListResponse {
  chartEntitlementDto: ChartEntitlementDto;
  dealerEntitlementDto: DealerEntitlementDto;
}

export interface ChartEntitlementDto {
  id: string;
  userId: string;
  userDevice: UserDevice;
  baseProductEntitlement: Entitlement;
  premiumProductEntitlement: Entitlement;
  lastDownloadedProductVersion: string;
  consumedDateTime: string;
  isExpired: boolean;
  createdDateTime: string;
  lastModifiedDateTime: string;
  updateAvailable: boolean;
  includeInDownload: boolean;
  isNew: boolean;
  isTrial: boolean;
  notes: string;
  chartDownloadDate: string;
  voucherCode: string;
  nhoUpdateDate: string;
  voucherType: number;
  smallChartDownloadedAcknowledged?: boolean;
  subscriptionNumber?: string;
}

export interface DealerEntitlementDto {
  chartEntitlementId: string;
  transferChartType: number;
  chartCustomName: string;
}

export interface VoucherIncludeChartEntitlementResponse {
  voucherCode: string;
  voucherType: number;
  chartData: any;
}

export interface Entitlement {
  id: string;
  productId: string;
  product: Product;
  areaOfInterestDtos: AreaOfInterest[];
  createdDateTime: string;
  lastModifiedDateTime: string;
  purchasedDateTime: string;
  expiresDateTime: string;
  isExpired: boolean;
}

export interface UserDevice {
  id: string;
  lighthouseId: string;
  errorCode: string;
  latestPackageChartName: string;
  latestPackageSuccess: string;
}

export interface Product {
  id: string;
  premiumProductId: string;
  premiumProduct: Product;
  polygon: PolygonData;
  name: string;
  lastUpdatedDateTime: string;
  version: string;
  sku: string;
  termDays: number;
  chartSizeKb?: number;
  baseMapAndCoverageSizeKb?: number;
  baseChartEstimateSizeKb?: number;
  chartType: number;
  supportedAreaOfInterestTypes: [];
  activeImage: string;
  inactiveImage: string;
  deletedDateTime: string;
  mergeGroup: number;
  warning: string;
  activeImagePng: string;
  inactiveImagePng: string;
  partNumber: string;
}

export interface ProductName {
  name: string;
  version: string;
}

export interface MyChartListState {
  data: GroupCardsData[];
  isFetching?: boolean;
  error?: any;
  isEmpty?: boolean;
}

export const removeLighthouseText = (productName: string) => {
  const lighthousePrefix = ['LightHouse Charts', 'Lighthouse Charts',
    'LightHouse Chart', 'Lighthouse Chart',
    'LightHouseCharts', 'LightHouseChart'];
  let newProductName = productName;

  for (const prefix of lighthousePrefix) {
    if (productName.startsWith(prefix)) {
      newProductName = productName.replace(prefix, '');
      if (newProductName.startsWith('-')) {
        newProductName = newProductName.replace('-', '');
      } else if (newProductName.startsWith(' - ')) {
        newProductName = newProductName.replace(' - ', '');
      }

      break;
    }
  }

  return newProductName;
};

export const removeLighthouseTextWithCharacter = (productName: string) => {
  const lighthousePrefix = ' - ';
  let newProductName = productName;
  if (productName) {
    const splitProductName = productName.split(lighthousePrefix);
    newProductName = splitProductName.length > 1 ? productName.split(lighthousePrefix)[1] : productName;
  }

  return newProductName;
};

export interface GroupCardsData {
  chartEntitlementId: string;
  cardType: number;
  lighthouseId?: string;
  isTrial: boolean;
  listChildChartEntitlementId?: string[];
  listChildCardsData?: CardsData[];
  listChildChartEntitlementName?: string[];
  listChartEntitlementAssign?: ListAssignEntitlement[];
  voucherType?: number;
  chartCustomName?: string;
  chartTransferType?: number;
  notes?: string;
}

export interface CardsData {
  isPremium: boolean;
  productId: string;
  productName: string;
  productFullName? :string;
  productLastUpdatedDateTime: string;
  productSizeKb?: number;
  baseMapAndCoverageSizeKb?: number;
  baseChartEstimateSizeKb?: number;
  chartEntitlementId: string;
  premiumEntitlementId: string;
  baseEntitlementId: string;
  cardType: number;
  purchasedDateTime: string;
  expiresDateTime: string;
  isExpired: boolean;
  isRedeemed: boolean;
  activeImage: string;
  inactiveImage: string;
  isUpdateAvailable: boolean;
  isIncludeInDownload: boolean;
  isNew: boolean;
  sku: string;
  skuPremium: string;
  lighthouseId?: string;
  errorCode: string;
  version: string;
  areaOfInterestDtos: AreaOfInterest[];
  isTrial: boolean;
  unavailable: boolean;
  mergeGroup: number;
  transferChartType?: number;
  chartCustomName?: string;
  notes: string;
  chartDownloadDate?: string;
  voucherCode?: string;
  voucherType?: number;
  warning: string;
  nhoUpdateDate: string;
  termDays?: number;
  packagedChartName?: string;
  packagedChartVersion?: string;
  packagedChartDateTime?: string;
  smallChartDownloadedAcknowledged?: boolean
  partNumber: string;
  subscriptionNumber?: string;
}

export interface LightHousePopupModel {
  lighthouseId: string;
  listNames: string[];
}

export interface CurrentGroupScrollPositionModel {
  groupId: string;
  lighthouseId: string;
  scrollPosition: number;
}

export interface CardModel {
  lighthouseId: string;
  listChildChartEntitlementName?: string[];
}

export interface DataModel {
  entitlementId: string;
  entitlementName: string;
  isIncludeDownload: boolean;
  isTrial: boolean;
  mergeGroup: number;
  productId: string;
  premiumSubscription: string;
  chartCustomName: string;
}

export interface DownloadStep {
  id: string;
  step: number;
  progress: number;
  lightHouseId?: string;
  fileName?: string;
}

export interface BuyChartTrial {
  chartEntitlementId: string;
  sku: string;
}

export interface ListAssignEntitlement {
  name: string;
  produceId: string;
  mergeGroup: number;
}

export interface AlreadyAssignPopupData {
  listAlreadyAssignEntitlementName: ListAssignEntitlement[];
  listCurrentEntitlementName: ListAssignEntitlement[];
  duplicateLightHouseId: boolean;
}

export interface UpdateChartNotesPayload {
  chartEntitlementIds: string[];
  notes: string;
}

export interface UpdateChartNotesResponse {
  result: boolean;
  code: number;
}
